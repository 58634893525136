import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import dayjs from "dayjs"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const BlogPage = ({ data }) => {
  const allPosts = data.allMdx.nodes

  return (
    <Layout>
      <Seo title="省事熊新闻" />
      <div className="mx-auto py-12 px-4 max-w-7xl sm:px-6 lg:px-8 lg:py-24">
        {allPosts.map(({ id, frontmatter, fields }) => (
          <div className="mb-10" key={id}>
            <Link to={`${fields.slug}`} key={id}>
              <div className="mt-8 space-y-4 sm:grid sm:grid-cols-3 sm:gap-6 sm:space-y-0 lg:gap-8">
                <div className="w-100 h-150">
                  <GatsbyImage
                    className="w-full h-48 object-cover lg:w-full lg:h-150 shadow-lg rounded-lg"
                    image={getImage(frontmatter.coverImage)}
                    alt=""
                  />
                </div>
                <div className="sm:col-span-2">
                  <div className="space-y-4">
                    <div className="text-lg">
                      <p className="text-xl lg:text-2xl font-extrabold text-primary">
                        {frontmatter.title}
                      </p>
                      <p className="mt-2 text-secondary prose-lg text-gray-500">
                        <time dateTime={frontmatter.publishedAt}>
                          {dayjs(frontmatter.publishedAt).format(
                            "YYYY[年]MM[月]DD[日]"
                          )}
                        </time>
                      </p>
                      <p className="mt-2 text-secondary prose-lg">
                        {frontmatter.summary}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        ))}
      </div>
    </Layout>
  )
}

export const query = graphql`
  {
    allMdx(
      filter: { fileAbsolutePath: { regex: "/blog/" } }
      sort: { fields: [frontmatter___publishedAt], order: DESC }
    ) {
      nodes {
        id
        excerpt(pruneLength: 250)
        frontmatter {
          title
          publishedAt
          summary
          coverImage {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        fields {
          slug
        }
        timeToRead
      }
    }
  }
`

export default BlogPage
